<template>
  <v-container>
    <div v-if="records.length > 0" class="ml-2">
      <PageTitle
        :text="`Record Sales For Fuels`"
        class="font font-weight-bold"
      />
    </div>
    <div v-if="records.length > 0" class="buttonText ml-1" id="result">
      <v-menu
        v-model="menu.edit"
        :close-on-content-click="false"
        transition="scale-transition"
        :nudge-left="10"
        offset-y
        min-width="auto"
        class="ft font-weight-medium  "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="font mt-2 ml-2 font-weight-medium "
            outlined
            v-bind="attrs"
            :disabled="isListLoading"
            v-on="on"
            min-width="120px"
            prepend-icon="done"
          >
            <span class="ft">{{
              dateFromDialog !== ""
                ? dateFromDialog
                : date
                ? date
                : "Change Sales Recording Date"
            }}</span>
          </v-btn>
        </template>
        <v-date-picker
          :min="daysToRecordSales"
          :max="today | timestamp('YYYY-MM-DD')"
          class="font font-weight-medium  "
          v-model="date"
          :events="fuelSalesDates"
          event-color="primary"
          @input="menu.edit = false"
        ></v-date-picker>
      </v-menu>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            :loading="isListLoading"
            icon
            fab
            @click="refreshPage"
            small
            v-on="on"
            color="primary"
            class="mt-2 ml-2"
          >
            <i class="material-icons-outlined">refresh</i>
          </v-btn>
        </template>
        <span class="  font font-weight-medium font-size-sm">Refresh Page</span>
      </v-tooltip>
      <v-btn
        text
        small
        outlined
        :loading="isPageLoading || loading"
        :disabled="isPageLoading || loading"
        color="primary"
        style="margin-top: 14px;"
        class="ml-3 mr-6 float-right"
        @click.stop="
          open('details', 'payments/listPaidBills', '?page=1&size=5')
        "
      >
        <span
          class=" font font-weight-bold font-size-sm"
          style="font-size: 12px !important;"
        >
          Add Bill Payment
        </span>
      </v-btn>
      <v-chip label color="primary" class="float-right mt-3">
        <span class=" font font-weight-medium font-size-sm text-uppercase">
          Unpaid MD Bill Amt:
          {{
            amountOwed
              | currencyFormat(
                currency && currency.length > 0 ? currency[0].code : "GHS"
              )
          }}
        </span>
      </v-chip>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <div
          v-if="isListLoading"
          class="d-flex flex-row flex-grow-1 justify-center"
        >
          <v-progress-circular
            width="2"
            size="40"
            indeterminate
            color="primary"
          />
        </div>

        <div
          v-if="records.length > 0"
          class="d-flex flex-row flex-grow-1 animate__animated animate__fadeIn"
          style="width: 100%;"
        >
          <ValidationObserver
            tag="div"
            class="d-flex flex-row flex-grow-1"
            v-slot="{ handleSubmit }"
          >
            <form
              style="width: 100%;"
              @submit.prevent="handleSubmit(confirmationAlert)"
            >
              <template>
                <div class="d-flex flex-column flex-fill flex-grow-1 font">
                  <div class="d-flex flex-column flex-grow-1">
                    <v-alert
                      v-if="recordedFuelSales.length > 0"
                      dense
                      type="warning"
                    >
                      <span class="font font-weight-medium font-size-sm"
                        >Some sales have already been recorded due to price
                        change -
                        {{
                          recordedFuelSales.reduce(
                            (acc, item) => (acc += item.totalAmountSold),
                            0
                          )
                            | currencyFormat(
                              currency && currency.length > 0
                                ? currency[0].code
                                : "GHS"
                            )
                        }}</span
                      >
                    </v-alert>
                  </div>
                  <div
                    class="d-flex flex-row flex-grow-1"
                    style="justify-content: space-between;"
                  >
                    <v-alert type="success" dense>
                      <span
                        class="font text-uppercase font-weight-bold font-size-sm"
                      >
                        Total Fuel Sales Revenue -
                        {{
                          (sum(recordedFuelSales, "totalAmountSold") +
                            sum(
                              pumps.reduce((acc, pump) => acc.concat(pump), []),
                              "totalAmountSold"
                            ) +
                            trackInputAmount.totalCreditCollection)
                            | currencyFormat(
                              currency && currency.length > 0
                                ? currency[0].code
                                : "GHS"
                            )
                        }}
                      </span>
                    </v-alert>
                    <v-alert type="success" dense>
                      <span
                        class="font text-uppercase font-weight-bold font-size-sm"
                      >
                        Fuel Revenue -
                        {{
                          (sum(recordedFuelSales, "totalAmountSold") +
                            sum(
                              pumps.reduce((acc, pump) => acc.concat(pump), []),
                              "totalAmountSold"
                            ))
                            | currencyFormat(
                              currency && currency.length > 0
                                ? currency[0].code
                                : "GHS"
                            )
                        }}
                      </span>
                    </v-alert>
                    <v-alert
                      v-if="trackInputAmount.totalCreditCollection > 0"
                      type="success"
                      dense
                    >
                      <span
                        class="font text-uppercase font-weight-bold font-size-sm"
                      >
                        Credit Collection -
                        {{
                          trackInputAmount.totalCreditCollection
                            | currencyFormat(
                              currency && currency.length > 0
                                ? currency[0].code
                                : "GHS"
                            )
                        }}
                      </span>
                    </v-alert>
                  </div>
                  <div class="d-flex flex-column flex-grow-1">
                    <v-expansion-panels focusable flat>
                      <v-expansion-panel
                        v-for="(record, index) in records"
                        :key="record.id"
                        class="box-shadow-light"
                      >
                        <v-expansion-panel-header>
                          <span class="font-weight-bold">
                            Pump:
                            <v-chip small label class="mr-2">{{
                              record.pumpId.name
                            }}</v-chip>
                            No. Of Nozzles:
                            <v-chip small label v-if="record.pumpId.nozzles">
                              {{ record.pumpId.nozzles.length }}</v-chip
                            >
                          </span>
                          <span class="font font-weight-medium text-center">
                            Attendant Name:
                            <v-chip small label class=" font font-size-sm">
                              {{ record.attendantId.name }}
                            </v-chip>
                          </span>
                          <span
                            class=" font font-weight-medium font-weight-bold text-right"
                          >
                            Sales Amt. -
                            <v-chip
                              small
                              label
                              color="primary"
                              class="font font-weight-medium font-size-md mr-3"
                            >
                              {{
                                pumps[index].reduce(
                                  (acc, sale) =>
                                    (acc += parseFloat(sale.totalAmountSold)),
                                  0
                                )
                                  | currencyFormat(
                                    currency && currency.length > 0
                                      ? currency[0].code
                                      : "GHS"
                                  )
                              }}
                            </v-chip>
                          </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div
                            v-if="pumps && pumps.length > 0"
                            class="d-flex flex-column flex-grow-1 flex-wrap"
                          >
                            <v-alert
                              v-if="
                                pumps
                                  .map(pump =>
                                    pump.some(pump => !pump.isStockAvailable)
                                  )
                                  .filter(available => available).length > 0
                              "
                              type="error"
                              dense
                              class="mx-2"
                            >
                              <span
                                class=" font font-weight-medium font-size-sm"
                                >Some products are low on stock hence not
                                showing here</span
                              >
                            </v-alert>
                            <template v-for="(pump, idx) in pumps[index]">
                              <div
                                v-if="pump.isStockAvailable"
                                :key="pump.id"
                                class="d-flex flex-column flex-fill flex-grow-1 flex-shrink-1 px-3 ma-2"
                                style="border-radius: 8px;"
                                :style="{
                                  backgroundColor: !pump.isStockAvailable
                                    ? 'rgb(191 0 0 / 15%)'
                                    : '#8272721c'
                                }"
                              >
                                <div
                                  class="d-flex flex-row font-weight-bold black--text"
                                  style="justify-content: space-between;"
                                >
                                  <div
                                    class="d-flex flex-row flex-grow-1 my-2"
                                    style="justify-content: space-between"
                                  >
                                    <span class="font-weight-bold"
                                      >{{ `Nozzle ${idx + 1}` }} /
                                      {{ pump.product.name }}
                                    </span>
                                    <v-chip label small color="primary">
                                      <span
                                        class="font font-weight-medium font-size-sm"
                                        >Stocks Available:
                                        {{ pump.stocks }}</span
                                      >
                                    </v-chip>
                                  </div>

                                  <v-chip
                                    v-if="!pump.isStockAvailable"
                                    label
                                    small
                                    class="mx-3"
                                    color="red"
                                    ><span
                                      class="font white--text font-weight-medium font-size-sm"
                                      >No stock available for this product</span
                                    ></v-chip
                                  >
                                </div>

                                <div class="d-flex flex-row flex-fill mt-1">
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    rules="required|decimals"
                                  >
                                    <v-text-field
                                      :error-messages="errors[0]"
                                      label="Opening Meter"
                                      readonly
                                      background-color="white"
                                      :value="pump.openingMeter"
                                      class="font-weight-medium ma-1"
                                      hint="Opening meter readings of the pump"
                                      outlined
                                    />
                                  </ValidationProvider>
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    :rules="
                                      'required|decimals|' +
                                        'min_value:' +
                                        pump.openingMeter
                                    "
                                  >
                                    <v-text-field
                                      label="Closing Meter"
                                      v-model="pump.closingMeter"
                                      :error-messages="errors[0]"
                                      background-color="white"
                                      :disabled="!pump.isStockAvailable"
                                      required
                                      class="font-weight-medium ma-1"
                                      hint="Closing meter readings of the pump"
                                      outlined
                                      append-icon="add_task"
                                      @click:append="
                                        pump.closingMeter = pump.openingMeter
                                      "
                                    />
                                  </ValidationProvider>
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    rules="required|decimals"
                                  >
                                    <v-text-field
                                      label="Sales (Litres)"
                                      readonly
                                      background-color="white"
                                      :error-messages="errors[0]"
                                      v-model="pump.salesInLiters"
                                      class="font-weight-medium ma-1"
                                      hint="Sales calculation is done per litres"
                                      outlined
                                    />
                                  </ValidationProvider>

                                  <v-text-field
                                    label="Price/Litre"
                                    :prefix="
                                      currency && currency.length > 0
                                        ? currency[0].symbol
                                        : 'GHS'
                                    "
                                    class="font-weight-medium ma-1"
                                    hint="Price per litre"
                                    outlined
                                    readonly
                                    background-color="white"
                                    v-model="pump.price"
                                    append-icon="add"
                                    color="primary"
                                    @click:append="
                                      open(
                                        'price',
                                        'products/details',
                                        pump.product.id
                                      )
                                    "
                                  />
                                  <ValidationProvider
                                    v-slot="{ errors }"
                                    rules="required|decimals"
                                  >
                                    <v-text-field
                                      label="Total Amount"
                                      background-color="white"
                                      readonly
                                      :error-messages="errors[0]"
                                      :prefix="
                                        currency && currency.length > 0
                                          ? currency[0].symbol
                                          : 'GHS'
                                      "
                                      v-model="pump.totalAmountSold"
                                      class="font-weight-medium ma-1"
                                      hint="Total amount"
                                      outlined
                                    />
                                  </ValidationProvider>
                                </div>
                              </div>
                            </template>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </div>
                <template v-if="!isSalesHalfway">
                  <RecordFuelSegregations
                    :date="date"
                    :modeOfPayments="modeOfPaymentList"
                    @openRevenueDialog="open('generate')"
                    @addRevenueRow="addRevenueRow"
                    :recordedFuelSalesList="recordedFuelSales"
                    :pumps="pumps"
                    :currency="currency"
                    :totalFuelSales="trackInputAmount.totalFuelSales"
                    :recordFuelSegregation="recordFuelSegregation"
                    :totalCreditCollection="
                      trackInputAmount.totalCreditCollection
                    "
                  />
                  <div class="d-flex flex-column flex-grow-1 flex-fill mt-3">
                    <div class="d-flex flex-row flex-grow-1 my-1">
                      <v-chip
                        label
                        class="font-weight-medium text-uppercase box-shadow-light"
                      >
                        Add Credit Sales And Collections From Customers
                      </v-chip>

                      <div class="d-flex flex-row flex-grow-1 mt-n1 ml-3">
                        <v-autocomplete
                          v-model="query"
                          :items="searchResults"
                          :search-input.sync="search"
                          chips
                          clearable
                          hide-details
                          return-object
                          flat
                          :loading="subLoading"
                          dense
                          hide-selected
                          :disabled="
                            sum(recordedFuelSales, 'totalAmountSold') +
                              sum(
                                pumps.reduce(
                                  (acc, pump) => acc.concat(pump),
                                  []
                                ),
                                'totalAmountSold'
                              ) +
                              trackInputAmount.totalCreditCollection <=
                              trackInputAmount.totalFuelSales
                          "
                          append-icon="search"
                          class="font font-weight-medium font-size-md box-shadow-light"
                          item-text="name"
                          label="Search for customers by their name ..."
                          solo
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-title
                                class="font font-weight-medium"
                              >
                                Search through all your
                                <strong>customer</strong> to record sales
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <template
                            v-slot:selection="{ attr, on, item, selected }"
                          >
                            <v-chip
                              v-bind="attr"
                              label
                              :input-value="selected"
                              color="primary"
                              small
                              class="white--text"
                              v-on="on"
                            >
                              <span
                                class="font font-weight-medium"
                                v-text="item.name"
                              ></span>
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item }">
                            <div class="d-flex flex-row flex-grow-1">
                              <span
                                class="font font-weight-medium font-size-md"
                                >{{ item.name }}</span
                              >
                              <v-chip
                                small
                                label
                                color="primary"
                                class="font ml-4 font-weight-medium font-size-sm"
                                >{{
                                  item.businessRegistrationNumber
                                    ? "Business Customer"
                                    : "Customer"
                                }}</v-chip
                              >
                            </div>
                          </template>
                        </v-autocomplete>
                      </div>
                    </div>

                    <div
                      v-if="customerSales.length > 0"
                      class="d-flex flex-column flex-grow-1 my-1 grey lighten-4 pt-2 pl-2 pr-2 pb-1"
                      style="border-radius: 3px;"
                    >
                      <div class="d-flex flex-row font font-weight-medium mb-3">
                        <v-chip
                          color="primary"
                          class="mx-3 font font-weight-medium "
                          small
                          label
                        >
                          Added Customers
                          <span
                            class=" font font-weight-medium font-size-sm ml-3"
                            >{{ customerSales.length }}</span
                          >
                        </v-chip>
                      </div>
                      <div
                        v-for="(customerSale, index) in customerSales"
                        :key="index"
                        class="d-flex flex-column flex-grow-1 mb-1 white pa-3  box-shadow-light "
                      >
                        <div class="d-flex flex-row mt-3 flex-grow-1">
                          <ValidationProvider
                            tag="div"
                            class="d-flex flex-row flex-grow-1"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              label="Customer Name"
                              dense
                              class="font font-weight-medium font-size-md"
                              prepend-icon="people"
                              readonly
                              :error-messages="errors[0]"
                              v-model="customerSale.name"
                            />
                          </ValidationProvider>

                          <ValidationProvider
                            tag="div"
                            class="d-flex flex-row flex-grow-1"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              label="Customer Acc. Balance"
                              dense
                              readonly
                              :error-messages="errors[0]"
                              :prefix="
                                currency && currency.length > 0
                                  ? currency[0].symbol
                                  : 'GHS'
                              "
                              prepend-icon="credit_card"
                              v-model="customerSale.amount"
                              class="font font-weight-medium font-size-md"
                            />
                          </ValidationProvider>
                          <ValidationProvider
                            tag="div"
                            class="d-flex flex-row flex-grow-1"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              label="Credit Sale"
                              prepend-icon="shopping_basket"
                              v-model="customerSale.creditSales"
                              class="font font-weight-medium font-size-md"
                              dense
                              :prefix="
                                currency && currency.length > 0
                                  ? currency[0].symbol
                                  : 'GHS'
                              "
                              :error-messages="errors[0]"
                            />
                          </ValidationProvider>
                          <ValidationProvider
                            tag="div"
                            class="d-flex flex-row flex-grow-1"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              label="Credit Collection"
                              prepend-icon="credit_card"
                              v-model="customerSale.creditCollection"
                              class="font font-weight-medium font-size-md"
                              :prefix="
                                currency && currency.length > 0
                                  ? currency[0].symbol
                                  : 'GHS'
                              "
                              dense
                              :error-messages="errors[0]"
                            />
                          </ValidationProvider>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                color="red"
                                @click.stop="removeCustomer(index)"
                                small
                              >
                                <i class="material-icons-outlined">remove</i>
                              </v-btn>
                            </template>
                            <span class="font font-weight-medium font-size-sm"
                              >Remove Customer</span
                            >
                          </v-tooltip>
                        </div>
                      </div>
                    </div>
                    <v-alert
                      v-if="!isAccountsTallied"
                      dense
                      type="error"
                      class="mx-2 mt-2"
                    >
                      <span class="font font-weight-medium font-size-sm"
                        >Account not tallied, kindly reaccess. Amount can be +/-
                        15 cedis</span
                      >
                    </v-alert>
                  </div>
                  <div class="d-flex flex-column flex-grow-1 flex-fill mt-3">
                    <div
                      class="d-flex flex-column flex-grow-1 grey lighten-4 "
                      style="border-radius: 3px;"
                      v-if="expenses.length > 0"
                    >
                      <span
                        class=" font font-size-md ml-2 mt-2 text-uppercase font-weight-medium"
                        >Other Expenses</span
                      >
                      <v-slide-y-transition group>
                        <div
                          v-for="(expense, index) in expenses"
                          :key="index"
                          class="d-flex flex-column flex-grow-1 my-1 white pa-2 ma-2 box-shadow-light"
                        >
                          <div
                            class="d-flex flex-row flex-grow-1 pl-3 pr-3 pt-3 pb-1"
                          >
                            <ValidationProvider
                              v-slot="{ errors }"
                              tag="div"
                              rules="required"
                              class="d-flex flex-grow-1"
                            >
                              <v-text-field
                                autofocus
                                v-model="expense.name"
                                label="Expenses Name"
                                prepend-icon="badge"
                                class="font font-weight-medium font-size-md"
                                dense
                                :error-messages="errors[0]"
                              />
                            </ValidationProvider>
                            <ValidationProvider
                              v-slot="{ errors }"
                              tag="div"
                              rules="required"
                              class="d-flex flex-grow-1"
                            >
                              <v-text-field
                                label="Expenses Amount/Value"
                                prepend-icon="credit_card"
                                v-model="expense.value"
                                :prefix="
                                  currency && currency.length > 0
                                    ? currency[0].symbol
                                    : 'GHS'
                                "
                                class="font font-weight-medium font-size-md"
                                dense
                                :error-messages="errors[0]"
                              />
                            </ValidationProvider>
                            <ValidationProvider
                              tag="div"
                              class="d-flex flex-grow-1"
                            >
                              <v-text-field
                                label="Comment"
                                v-model="expense.comment"
                                prepend-icon="edit"
                                class="font font-weight-medium font-size-md"
                                dense
                              />
                            </ValidationProvider>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  color="red"
                                  @click.stop="removeExpensesRow(index)"
                                  small
                                >
                                  <i class="material-icons-outlined">remove</i>
                                </v-btn>
                              </template>
                              <span class="font font-weight-medium font-size-sm"
                                >Remove Item</span
                              >
                            </v-tooltip>
                          </div>
                        </div>
                      </v-slide-y-transition>

                      <div
                        class="d-flex flex-row justify-end my-2 mr-2 mb-2 flex-grow-1"
                      >
                        <v-btn
                          :disabled="
                            sum(recordedFuelSales, 'totalAmountSold') +
                              sum(
                                pumps.reduce(
                                  (acc, pump) => acc.concat(pump),
                                  []
                                ),
                                'totalAmountSold'
                              ) +
                              trackInputAmount.totalCreditCollection <=
                              trackInputAmount.totalFuelSales
                          "
                          @click="addExpensesRow"
                          small
                          color="primary"
                        >
                          <span
                            class="font font-weight-medium font-size-sm"
                            style="font-size: 12px !important;"
                          >
                            Add New Expense
                          </span>
                        </v-btn>
                      </div>
                    </div>
                    <div
                      v-else
                      class="d-flex flex-column flex-grow-1 my-1 justify-center grey lighten-4 pa-5"
                      style="align-items:center;"
                    >
                      <img
                        src="@/assets/online-payment.svg"
                        style="width: 80px;"
                      />
                      <span class="font font-weight-medium"
                        >Record any other expenses as part of the daily sales
                        here <kbd class="ml-1">{{ date }}</kbd></span
                      >
                      <span class="font font-weight-medium font-size-sm"
                        >Expenses may include funds disburse for work use etc.
                      </span>
                      <v-btn
                        @click="addExpensesRow"
                        small
                        :disabled="
                          sum(recordedFuelSales, 'totalAmountSold') +
                            sum(
                              pumps.reduce((acc, pump) => acc.concat(pump), []),
                              'totalAmountSold'
                            ) +
                            trackInputAmount.totalCreditCollection <=
                            trackInputAmount.totalFuelSales
                        "
                        color="primary"
                        class="mt-2"
                      >
                        <span
                          class="font font-weight-medium font-size-sm"
                          style="font-size: 12px !important;"
                          >Add Expenses</span
                        >
                      </v-btn>
                    </div>
                  </div>
                  <RecordedLubes
                    :recordedLubeSales="recordedLubeSales"
                    :currency="currency"
                    :loading="loading"
                    :date="date"
                  />
                  <RecordOccurrences
                    :currency="currency"
                    :date="date"
                    :loading="loading"
                    :failedTransactions="failedTransactions"
                    @addFailedTransaction="addFailedTransaction"
                    @removeFailedTransaction="removeFailedTransaction"
                  />
                  <MDsBills
                    :date="date"
                    :loading="loading"
                    :currency="currency"
                    :bills="MDBills"
                    :recordedFuelSalesList="recordedFuelSales"
                    :totalCreditCollection="
                      trackInputAmount.totalCreditCollection
                    "
                    :totalFuelSales="trackInputAmount.totalFuelSales"
                    :pumps="pumps"
                    @addBillRow="addBillRow"
                  />

                  <div
                    class="d-flex flex-column flex-grow-1 flex-fill pa-3 grey lighten-4 mt-1"
                  >
                    <div
                      class="d-flex flex-row font-weight-medium text-uppercase px-3 white py-3"
                    >
                      <div class="font font-weight-medium font-size-md mt-3">
                        Total Shop Sales
                      </div>
                      <v-text-field
                        :value="recordedShopSales.salesAmount"
                        dense
                        outlined
                        readonly
                        hide-details
                        prepend-icon="storefront"
                        class="ml-4 mt-1 font font-weight-medium font-size-sm"
                        :prefix="
                          currency && currency.length > 0
                            ? currency[0].symbol
                            : 'GHS'
                        "
                        label="Total recorded shop sales"
                      />

                      <v-text-field
                        v-if="recordedShopSales.cashDeposit"
                        :value="recordedShopSales.cashDeposit.toFixed(2)"
                        dense
                        outlined
                        readonly
                        hide-details
                        prepend-icon="local_mall"
                        class="ml-4 mt-1 font font-weight-medium font-size-sm"
                        :prefix="
                          currency && currency.length > 0
                            ? currency[0].symbol
                            : 'GHS'
                        "
                        label="Cash to deposit"
                      />
                      <v-text-field
                        :value="recordedShopSales.mdsAllocationAmount"
                        dense
                        outlined
                        readonly
                        hide-details
                        prepend-icon="payments"
                        class="ml-4 mt-1 font font-weight-medium font-size-sm"
                        :prefix="
                          currency && currency.length > 0
                            ? currency[0].symbol
                            : 'GHS'
                        "
                        label="MD's Allocation Amount"
                      />
                    </div>
                  </div>
                  <template v-if="trackInputAmount.returnToTank.length > 0">
                    <div
                      class="d-flex flex-column flex-grow-1 flex-fill pa-3 grey lighten-4 mt-1"
                    >
                      <div
                        v-for="(rtt, index) in trackInputAmount.returnToTank"
                        :key="index"
                        class="d-flex flex-row mt-1 font-weight-medium text-uppercase px-3 white pb-3 pt-5"
                      >
                        <div class="font font-weight-medium font-size-md mt-3">
                          Return To Tank
                        </div>
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-autocomplete
                            dense
                            :items="products"
                            item-value="id"
                            return-object
                            item-text="name"
                            v-model="rtt.productId"
                            outlined
                            :error-messages="errors[0]"
                            prepend-icon="sanitizer"
                            class="font font-weight-medium font-size-sm mt-1 ml-2"
                            label="Choose a product"
                          >
                            <template #item="{item}">
                              <span
                                class="font font-weight-medium font-size-sm"
                                >{{ item.name }}</span
                              >
                            </template>
                          </v-autocomplete>
                        </ValidationProvider>

                        <v-text-field
                          v-model="rtt.liters"
                          dense
                          outlined
                          hide-details
                          prepend-icon="credit_card"
                          class="ml-4 mt-1 font font-weight-medium font-size-sm"
                          :prefix="'Lts'"
                          label="Total Amount In Litres"
                        />

                        <v-text-field
                          v-model="rtt.comment"
                          dense
                          outlined
                          hide-details
                          prepend-icon="comment"
                          class="ml-4 mt-1 font font-weight-medium font-size-sm"
                          label="Comment"
                        />
                        <v-btn
                          small
                          text
                          @click.stop="removeRTTRow(index)"
                          icon
                          class="mt-2 mx-1"
                          color="primary"
                        >
                          <i class="material-icons-outlined">remove</i>
                        </v-btn>
                      </div>
                      <div
                        class="d-flex flex-row flex-grow-1 justify-start pt-3"
                      >
                        <v-btn
                          :disabled="
                            trackInputAmount.returnToTank.length ===
                              products.length
                          "
                          small
                          @click.stop="addRTTRow"
                          color="primary"
                        >
                          <span
                            class=" font font-weight-medium font-size-sm"
                            style="font-size: 12px !important;"
                            >Add New Row</span
                          >
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="d-flex flex-column flex-grow-1 justify-center grey lighten-4 pa-5 mt-2"
                      style="align-items: center"
                    >
                      <img src="@/assets/discount.png" style="width: 80px;" />
                      <span class=" font font-weight-medium font-size-md">
                        Add return to tank products here
                      </span>
                      <span class=" font font-weight-medium font-size-sm">
                        Any product that have return to tank quantity should be
                        added here
                      </span>
                      <v-btn
                        small
                        @click.stop="addRTTRow"
                        color="primary"
                        class="mt-2"
                      >
                        <span
                          class=" font font-weight-medium font-size-sm"
                          style="font-size: 12px !important;"
                          >Add New Row</span
                        >
                      </v-btn>
                    </div>
                  </template>

                  <div
                    v-if="!isSalesHalfway"
                    class="d-flex flex-column flex-grow-1 flex-fill pa-3 grey lighten-4 mt-3"
                  >
                    <div class="d-flex flex-column flex-row-l">
                      <h4 class="font font-weight-medium text-uppercase">
                        Record Measured Stock Levels
                      </h4>
                      <span class=" font font-weight-medium font-size-sm pb-2">
                        These stock level recordings for the products will be
                        used by the system to calculate variations
                      </span>
                    </div>
                    <div
                      v-for="(variation, index) in stockVariations"
                      :key="index"
                      class="d-flex flex-row mt-1 font-weight-medium text-uppercase px-3 white py-3"
                    >
                      <v-autocomplete
                        dense
                        :items="products"
                        item-value="id"
                        item-text="name"
                        v-model="variation.productId"
                        outlined
                        readonly
                        prepend-icon="sanitizer"
                        class="font font-weight-medium font-size-sm mt-1 ml-2"
                        hide-details
                        label="Product/Fuel Name"
                      >
                        <template #item="{item}">
                          <span class="font font-weight-medium font-size-sm">{{
                            item.name
                          }}</span>
                        </template>
                      </v-autocomplete>
                      <v-text-field
                        v-model="variation.physicalStockLevel"
                        dense
                        outlined
                        hide-details
                        prepend-icon="inventory"
                        class="ml-4 mt-1 font font-weight-medium font-size-sm"
                        :prefix="'Lts'"
                        label="Measured Stock Levels (Litres)"
                      />
                    </div>
                  </div>
                </template>
              </template>
              <div class="d-flex flex-column flex-grow-1 mt-4">
                <v-btn
                  v-if="isSalesHalfway"
                  :disabled="loading"
                  :loading="loading"
                  color="primary"
                  type="submit"
                >
                  <span
                    class="font font-weight-semibold text-uppercase font-size-md"
                    >Record Sales For {{ date }}</span
                  >
                </v-btn>
                <v-btn
                  v-else
                  color="primary"
                  type="submit"
                  :disabled="
                    !isAccountsTallied ||
                      totalFuelRecordings === 0 ||
                      Number(
                        totalFuelRecordings - trackInputAmount.totalFuelSales
                      ) > 15 ||
                      loading
                  "
                  :loading="loading"
                >
                  <span
                    class="font font-weight-semibold text-uppercase font-size-md"
                    >Close Sales For {{ date }}
                  </span>
                </v-btn>
              </div>
            </form>
          </ValidationObserver>
        </div>

        <template v-else>
          <div
            class="d-flex flex-row flex-fill justify-center mt-16 animate__animated animate__fadeIn"
          >
            <img
              :src="require('@/assets/calendar.svg')"
              style="width: 150px;"
            />
          </div>
          <div class="d-flex flex-column flex-fill align-center mt-10">
            <span
              class="font font-weight-medium pt-2 animate__animated animate_fadeInDown"
            >
              Select A Date To Start Sales Recordings
            </span>

            <v-chip
              color="primary"
              label
              small
              class="font font-weight-medium font-size-sm my-2"
              >Instructions to Follow</v-chip
            >
            <span class="font font-weight-medium font-size-sm"
              >Choose <kbd>Today's date</kbd> to record sales when there's a
              price change</span
            >
            <span class="font font-weight-medium font-size-sm pt-2"
              >Choose <kbd>yesterday's date</kbd> to close the overall daily
              sale</span
            >
            <p>
              <v-menu
                v-model="menu.add"
                :close-on-content-click="false"
                transition="scale-transition"
                :nudge-left="10"
                offset-y
                min-width="auto"
                class="font font-weight-medium  "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="font mt-4 font-weight-medium"
                    outlined
                    v-bind="attrs"
                    :disabled="isListLoading"
                    v-on="on"
                    min-width="120px"
                  >
                    <span class=" font font-weight-medium">{{
                      date ? date : "Choose A Date"
                    }}</span>
                  </v-btn>
                </template>
                <v-date-picker
                  :min="daysToRecordSales"
                  event-color="primary"
                  :events="fuelSalesDates"
                  :max="today | timestamp('YYYY-MM-DD')"
                  class="font font-weight-medium  "
                  v-model="date"
                  @input="menu.add = false"
                ></v-date-picker>
              </v-menu>
            </p>
            <v-alert
              type="error"
              dense
              outlined
              v-if="futureDateError"
              class="font font-weight-medium font-size-md "
            >
              Date cannot be in the future
            </v-alert>
            <span v-if="isListLoading">
              <v-progress-circular
                indeterminate
                color="primary"
                size="30"
                width="2"
              />
            </span>
          </div>
        </template>
      </v-col>
    </v-row>
    <template v-if="date !== ''">
      <AmountAccountedFor
        v-if="amountOwed > 0"
        style="bottom: 21% !important"
        :currency="currency"
        label="UNPAID MD BILL AMT."
        icon="account_balance_wallet"
        :totalAmount="amountOwed"
      />
      <AmountAccountedFor
        style="bottom: 13% !important"
        :currency="currency"
        label="AMOUNT LEFT"
        icon="point_of_sale"
        :totalAmount="totalFuelRecordings - trackInputAmount.totalFuelSales"
      />
      <AmountAccountedFor
        :currency="currency"
        icon="credit_score"
        label="AMOUNT ADDED"
        :totalAmount="trackInputAmount.totalFuelSales"
      />
    </template>
    <AssignAttendantToPumpDialog
      :state="dialog('add')"
      @close="closeDialog"
      :loading="loading"
      :selectedDate="date"
      :minDate="daysToRecordSales"
      @searchRecords="getSalesRecordsData"
      @assignAttendantToPumps="assignAttendantToPumps"
    />
    <AddNewPrice
      :state="dialog('price')"
      :loading="subLoading"
      @close="close"
      @addNewPrice="addNewPrice"
      @updateCurrentPrice="updateCurrentPrice"
      :currency="currency"
      :availablePrices="availableProductPrices"
    />
    <ConfirmationDialog
      :state="dialog('alert')"
      :loading="loading"
      @save="recordDailySales"
      @close="close"
    />
    <SnackBar
      :showSnackBar="showSnackBar"
      :status="status"
      :message="message"
    />
    <AddRevenueType
      :state="dialog('generate')"
      @addNewRevenueType="addNewRevenueType"
      :loading="loading"
      @close="close"
    />
    <AddBillPayment
      :state="dialog('details')"
      :billPayments="billPaymentList"
      :paginate="billPagination"
      @close="close"
      :loading="isPageLoading || loading"
    />
  </v-container>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import moment from "moment";
import { mapGetters } from "vuex";
import { timestamp } from "../../filters/timestamp";
import { currencyFormat } from "../../filters/currencyFormat";
import DialogMixins from "../../mixins/DialogMixins";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { min_value, numeric, required } from "vee-validate/dist/rules";
import {
  cloneDeep,
  debounce,
  flattenDeep,
  groupBy as lodashGroupBy
} from "lodash";
import SnackBarMixins from "../../mixins/SnackBarMixins";
import { decimals, roundAccurately } from "../../utils/resolvers";
extend("min_value", {
  ...min_value,
  message: "Invalid closing meter value provided"
});
extend("numeric", {
  ...numeric,
  message: "Input field must contain only numbers"
});
extend("required", {
  ...required,
  message: "Input field is required"
});
extend("decimals", decimals);
export default {
  name: "RecordSales",
  components: {
    ValidationProvider,
    ValidationObserver,
    AddRevenueType: () => import(`./dialogs/AddRevenueType`),
    RecordOccurrences: () => import(`./components/RecordOccurrences`),
    SnackBar: () => import(`../../components/SnackBar`),
    PageTitle,
    RecordedLubes: () => import(`./components/RecordedLubes`),
    RecordFuelSegregations: () => import(`./components/RecordFuelSegregations`),
    AddNewPrice: () => import(`./dialogs/AddNewPrice`),
    AssignAttendantToPumpDialog: () =>
      import(`./dialogs/AssignAttendantToPumpDialog`),
    ConfirmationDialog: () => import(`./components/ConfirmationDialog`),
    MDsBills: () => import(`./components/MDsBills`),
    AmountAccountedFor: () => import(`./components/AmountAccountedFor`),
    AddBillPayment: () => import(`./dialogs/AddBillPayment`)
  },
  data() {
    return {
      menu: {
        add: false,
        edit: false
      },
      today: new Date(),
      date: "",
      dateFromDialog: "",
      futureDateError: false,
      records: [],
      pumps: [],
      search: null,
      query: null,
      customerSales: [],
      expenses: [],
      isSalesHalfway: true,
      daysToRecordSales: null,
      failedTransactions: [],
      recordFuelSegregation: [],
      MDBills: [],
      trackInputAmount: {
        totalFuelSales: 0, // this is the sum of the pumps value, the recorded sales and credit collection
        totalCreditCollection: 0,
        totalCreditSales: 0,
        totalLubeSales: 0,
        totalShopSales: 0,
        totalExpenses: 0,
        totalFailedTransactions: 0,
        totalBills: 0,
        returnToTank: []
      },
      isAccountsTallied: true,
      totalFuelRecordings: 0, // recordings that will be activated on adding of segregations,
      stockVariations: []
    };
  },
  mixins: [DialogMixins, SnackBarMixins],
  filters: {
    timestamp,
    currencyFormat,
    today(value) {
      return moment(value).format("Do MMMM, YYYY");
    }
  },
  computed: {
    ...mapGetters({
      loading: "getIsLoading",
      isListLoading: "getIsListLoading",
      salesRecords: "sales/getDataToRecordSales",
      currency: "currency/getSystemCurrency",
      subLoading: "getIsSubLoading",
      searchResults: "customers/getCustomerSearchResults",
      recordedLubeSales: "sales/getRecordedLubeSales",
      recordedFuelSales: "sales/getRecordedFuelSales",
      availableProductPrices: "prices/getProductPriceList",
      settings: "settings/getSystemSettings",
      modeOfPaymentList: "currency/getModeOfPaymentList",
      fuelSalesDates: "sales/getRecordedDates",
      recordedShopSales: "sales/getRecordedShopSales",
      products: "products/getProductList",
      billPaymentList: "payments/getBillPaymentsList",
      billPagination: "payments/getPagination",
      isPageLoading: "getIsPageLoading",
      amountOwed: "payments/getAmountOwed"
    })
  },
  watch: {
    products(payload) {
      this.stockVariations = payload.map(product => {
        return {
          productId: product?.id,
          physicalStockLevel: 0
        };
      });
    },
    "trackInputAmount.totalFuelSales"(value) {
      this.totalFuelRecordings =
        this.sum(this.recordedFuelSales, "totalAmountSold") +
        this.sum(
          this.pumps.reduce((acc, pump) => acc.concat(pump), []),
          "totalAmountSold"
        ) +
        this.trackInputAmount.totalCreditCollection;

      const balance = this.totalFuelRecordings - value;
      this.isAccountsTallied = balance >= -15;
    },
    recordFuelSegregation: {
      handler(sales) {
        this.trackInputAmount.totalFuelSales = Number(
          this.sum(sales, "amount") +
            this.sum(this.customerSales, "creditSales") +
            this.sum(this.expenses, "value") +
            this.sum(this.MDBills, "amount")
        );
      },
      deep: true
    },
    customerSales: {
      handler(sales) {
        this.trackInputAmount.totalFuelSales = Number(
          this.sum(sales, "creditSales") +
            this.sum(this.recordFuelSegregation, "amount") +
            this.sum(this.expenses, "value") +
            this.sum(this.MDBills, "amount")
        );

        this.trackInputAmount.totalCreditCollection = Number(
          this.sum(sales, "creditCollection")
        );
      },
      deep: true
    },
    "trackInputAmount.totalCreditCollection"(value) {
      this.totalFuelRecordings =
        this.sum(this.recordedFuelSales, "totalAmountSold") +
        this.sum(
          this.pumps.reduce((acc, pump) => acc.concat(pump), []),
          "totalAmountSold"
        ) +
        value;
      this.isAccountsTallied =
        Number(this.totalFuelRecordings) -
          Number(this.trackInputAmount.totalFuelSales) >=
        -15;
      //totalFuelRecordings - trackInputAmount.totalFuelSales
    },
    expenses: {
      handler(expenses) {
        this.trackInputAmount.totalFuelSales = Number(
          this.sum(expenses, "value") +
            this.sum(this.recordFuelSegregation, "amount") +
            this.sum(this.customerSales, "creditSales") +
            this.sum(this.MDBills, "amount")
        );
      },
      deep: true
    },
    MDBills: {
      handler(bills) {
        this.trackInputAmount.totalFuelSales = Number(
          this.sum(bills, "amount") +
            this.sum(this.recordFuelSegregation, "amount") +
            this.sum(this.customerSales, "creditSales") +
            this.sum(this.expenses, "value")
        );
      },
      deep: true
    },
    settings({ daysAllowedForRecordingFuelSales }) {
      this.daysToRecordSales = moment()
        .subtract(daysAllowedForRecordingFuelSales, "days")
        .format("YYYY-MM-DD");
    },
    salesRecords(items) {
      if (items.length === 0) {
        this.open("add");
      }
      this.records = cloneDeep(items);
      this.pumps = [
        ...this.records?.map(record => {
          return record.pumpId.nozzles?.map(nozzle => {
            const productId = nozzle?.productId;
            return {
              dateAssigned: record?.dateAssigned,
              pumpAssignAttendantId: record?.id,
              nozzleId: nozzle?.id,
              nozzleName: nozzle?.name,
              product: {
                id: productId.id,
                name: productId?.name,
                minimumThreshold: productId?.minimumThreshold
              },
              price: productId?.prices?.amount,
              openingMeter: Number(nozzle?.meterReading),
              salesInLiters: 0,
              closingMeter: "",
              totalAmountSold: 0,
              stocks:
                productId.stocks && productId.stocks.quantity
                  ? Number(productId.stocks.quantity)
                  : 0,
              isStockAvailable:
                !!productId.stocks && productId.stocks.quantity > 0
            };
          });
        })
      ];
    },
    date(value) {
      this.getSalesRecordsData(value);
    },
    pumps: {
      handler(payload) {
        payload?.forEach(record => {
          record?.map(pumpItem => {
            let results = roundAccurately(
              Number(pumpItem.closingMeter) - Number(pumpItem.openingMeter),
              2
            );

            pumpItem.salesInLiters =
              isNaN(results) || Number(results) < 0 ? 0 : Number(results);

            let totalAmount = parseFloat(results * pumpItem.price).toFixed(2);
            pumpItem.totalAmountSold =
              isNaN(totalAmount) || totalAmount < 0
                ? 0
                : roundAccurately(totalAmount, 2);
          });
        });
        this.totalFuelRecordings =
          this.sum(this.recordedFuelSales, "totalAmountSold") +
          this.sum(
            payload.reduce((acc, pump) => acc.concat(pump), []),
            "totalAmountSold"
          ) +
          this.trackInputAmount.totalCreditCollection;
      },
      deep: true
    },
    search: debounce(function(query) {
      if (query) this.$store.dispatch("customers/searchCustomers", { query });
    }, 500),
    query(payload) {
      if (payload) {
        const { name, id, email, phone, contact, amount, slug } = payload;
        let results = {
          name,
          id,
          email,
          phone,
          contact,
          isBusiness: !!payload.businessRegistrationNumber,
          creditSales: 0,
          creditCollection: 0,
          amount,
          slug
        };

        if (
          !this.customerSales.find(
            customer =>
              customer.email === results.email && customer.slug === results.slug
          )
        ) {
          this.customerSales = [
            ...this.customerSales.filter(
              customer =>
                customer.email !== results.email &&
                customer?.slug !== results.slug
            ),
            results
          ];
        }
      }
    }
  },
  methods: {
    refreshPage() {
      this.$store.dispatch("sales/dataToRecordSales", {
        date: this.dateFromDialog !== "" ? this.dateFromDialog : this.date
      });
      this.$store.dispatch("payments/amountOwed");
    },
    getSalesRecordsData(value) {
      this.close("add");
      this.dateFromDialog = value;
      this.trackInputAmount.totalFuelSales = 0;
      if (this.fuelSalesDates.includes(value)) {
        this.$store.dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `Sales already recorded and finalize for ${this.date}`,
            status: "red"
          },
          { root: true }
        );
        setTimeout(
          () => this.$store.dispatch("resetSnackBarState", null),
          2000
        );
        return;
      }
      if (this.isSalesHalfway) {
        this.$store.dispatch("sales/recordedFuelSales", {
          date: moment(value).format("YYYY-MM-DD")
        });
      }
      if (
        moment(value).isValid() &&
        !moment(value).isAfter(moment()) &&
        value !== ""
      ) {
        this.$store.dispatch("sales/dataToRecordSales", { date: value });
        this.futureDateError = false;
      } else {
        this.date = "";
        this.pumps = this.records = [];
        this.futureDateError = value === "" ? false : true;
      }
      if (
        moment(value).isValid() &&
        moment(value, "YYYY-MM-DD").isBefore(moment().format("YYYY-MM-DD"))
      ) {
        this.$store.dispatch("sales/recordedLubeSales", {
          date: moment(value).format("YYYY-MM-DD")
        });
        this.$store.dispatch("sales/recordedFuelSales", {
          date: moment(value).format("YYYY-MM-DD")
        });
        this.$store.dispatch("sales/recordedShopSales", {
          date: value
        });
      }
      if (
        moment(value).isValid() &&
        moment(value, "YYYY-MM-DD").isSame(moment().format("YYYY-MM-DD"))
      ) {
        this.isSalesHalfway = true;
      } else {
        this.isSalesHalfway = false;
      }
    },
    sum(items, key) {
      return items.reduce(
        (acc, item) =>
          (acc += isNaN(parseFloat(item[key])) ? 0 : parseFloat(item[key])),
        0
      );
    },
    addNewPrice(payload) {
      this.$store.dispatch("products/addPrice", payload);
    },
    updateCurrentPrice(payload) {
      this.$store.dispatch("prices/update", payload);
    },
    removeCustomer(index) {
      this.customerSales.splice(index, 1);
    },
    assignAttendantToPumps(payload) {
      this.$store.dispatch("sales/assignAttendantsToPumps", payload);
    },
    closeDialog(value) {
      this.date = "";
      this.close(value);
    },
    confirmationAlert() {
      this.open("alert");
    },
    recordDailySales() {
      const results = this.pumps?.map(sales => {
        return sales?.map(({ product, ...rest }) => {
          return {
            ...rest,
            ...(isNaN(Number(rest?.closingMeter)) ||
              (Number(rest?.closingMeter) <= 0 && {
                closingMeter: rest?.openingMeter
              })),
            productId: product?.id,
            productName: product?.name
          };
        });
      });
      if (this.isSalesHalfway) {
        this.$store.dispatch("sales/recordSalesHalfway", { sales: results });
      } else {
        // calculate rtt by price if available
        let rttPayload = this.trackInputAmount.returnToTank.filter(
          rtt => Number(rtt.liters) > 0
        );
        let rttAmounToDeduct = rttPayload
          .map(({ productId, liters }) => {
            return {
              price: Number(productId?.prices) ?? 0,
              liters: Number(liters)
            };
          })
          .reduce((acc, cur) => (acc += cur.price * cur.liters), 0);

        // flatten the nozzle values
        const flattenSalesList = lodashGroupBy(
          flattenDeep(results).map(({ stocks, productId, salesInLiters }) => {
            return {
              stocks,
              productId,
              salesInLiters
            };
          }),
          "productId"
        );

        let payload = {
          sales: results,
          closingSaleDate: moment(this.date).format("YYYY-MM-DD"),
          totalTrackAmount: {
            ...this.trackInputAmount,
            totalFuelSales: roundAccurately(
              this.trackInputAmount.totalFuelSales - rttAmounToDeduct,
              2
            ),
            totalFailedTransactions: this.sum(
              this.failedTransactions,
              "amount"
            ),
            totalCreditCollection: this.sum(
              this.customerSales,
              "creditCollection"
            ),
            totalCreditSales: roundAccurately(
              this.sum(this.customerSales, "creditSales"),
              2
            ),
            totalExpenses: this.sum(this.expenses, "value"),
            totalShopSales: this.recordedShopSales?.salesAmount ?? 0,
            totalBills: this.sum(this.MDBills, "amount"),
            totalLubeSales: this.sum(this.recordedLubeSales, "amount"),
            totalFuelRevenueVariation: roundAccurately(
              this.totalFuelRecordings - this.trackInputAmount.totalFuelSales,
              2
            ),
            returnToTank: rttPayload.map(({ productId, liters, comment }) => {
              return {
                productId: productId?.id,
                liters: Number(liters),
                comment
              };
            })
          },
          isSaleHalfway: this.isSalesHalfway,
          segregations: {
            customerSales: this.customerSales,
            expenses: this.expenses,
            bills: this.MDBills,
            failedTransactions: this.failedTransactions,
            recordFuelSegregation: this.recordFuelSegregation.map(
              fuelSegregation => {
                return {
                  amount: Number(fuelSegregation.amount),
                  mode: fuelSegregation?.mode?.id,
                  modeName: fuelSegregation?.mode?.name,
                  modeSlug: fuelSegregation?.mode?.slug
                };
              }
            )
          },
          stockVariations: this.stockVariations.map(
            ({ productId, physicalStockLevel }) => {
              const stocksSold = flattenSalesList[productId].reduce(
                (acc, cur) => (acc += cur.salesInLiters),
                0
              );
              return {
                productId,
                physicalStockLevel: Number(physicalStockLevel),
                date: this.date,
                stocksSold: Number(stocksSold),
                systemStockLevel:
                  Number(flattenSalesList[productId][0].stocks) -
                  Number(stocksSold)
              };
            }
          )
        };

        if (payload.totalTrackAmount.totalFuelSales < 0) {
          this.$store.dispatch(
            "showSnackBar",
            {
              snackBar: true,
              message: `Fuels revenue after RTT deductions is negative --> ${payload.totalTrackAmount.totalFuelSales}, please revise`,
              status: "red"
            },
            { root: true }
          );
          return;
        }

        this.$store.dispatch("sales/recordOverallFuelAndDailySales", payload);
      }
    },
    addExpensesRow() {
      this.expenses.push({
        name: "",
        value: "",
        comment: ""
      });
    },
    removeExpensesRow(index) {
      this.expenses.splice(index, 1);
    },
    addNewRevenueType(payload) {
      this.$store.dispatch("currency/createModeOfPayment", payload);
    },
    addFailedTransaction(payload) {
      this.failedTransactions.push(payload);
    },
    removeFailedTransaction(index) {
      this.failedTransactions.splice(index, 1);
    },
    addRevenueRow(payload) {
      this.recordFuelSegregation.push(payload);
    },
    addBillRow(payload) {
      this.MDBills.push(payload);
    },
    addRTTRow() {
      this.trackInputAmount.returnToTank.push({
        productId: null,
        liters: 0,
        comment: ""
      });
    },
    removeRTTRow(index) {
      this.trackInputAmount.returnToTank.splice(index, 1);
    }
  },
  created() {
    this.$store.dispatch("sales/pumps");
    this.$store.dispatch("sales/pumpAttendants", { status: true });
    this.$store.dispatch("currency/getSystemCurrency");
    this.$store.dispatch("settings/getSettings");
    this.$store.dispatch("products/list", {
      isPaginated: false,
      page: 1,
      limit: 15
    });
    this.totalFuelRecordings =
      this.sum(this.recordedFuelSales, "totalAmountSold") +
      this.sum(
        this.pumps.reduce((acc, pump) => acc.concat(pump), []),
        "totalAmountSold"
      ) +
      this.trackInputAmount.totalCreditCollection;
    this.$store.dispatch("sales/datesForRecordedFuelSales");
    this.$store.dispatch("payments/amountOwed");
    //this.$store.dispatch("sales/dataToRecordSales", { date: this.date });
  }
};
</script>

<style scoped></style>
